import { Component, OnInit } from '@angular/core';
import { ReportService } from 'src/app/showcase/service/report.service';
import * as moment from 'moment';
import { InitService } from 'src/app/showcase/service/init.service';
import { DivisionService } from 'src/app/showcase/service/division.service';
@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  totalRecharge = 0;
  totalPayment = 0;
  balance = 0;
  constructor(  private reportService: ReportService,private initService:InitService,private service:DivisionService) { }
  selectedMonth:any 
  maxMonth:any;
  ngOnInit(): void {
    this.selectedMonth = moment().utc().toDate();
    this.maxMonth = moment().utc().toDate();
    // let startDate =  moment().startOf('month');
    // let endDate =  moment().endOf('month');
    // let param = {
    //   "startDate":startDate,
    //   "endDate":endDate
    // }

    this.getCount();
    // this.reportService.getDasboardCount(param).subscribe(res=>{
    //   this.initService.loaderShow();
    //   //console.log(res);
    //   if(res){
    //     this.initService.loaderHide();
    //     this.totalRecharge = res.recharge;
    //     this.totalPayment = res.payment;
    //     this.balance = res.recharge-res.payment;
    //   }
      
    // })
  }

  getCount(){
    if(this.selectedMonth == ""){
      return
    }
    this.initService.loaderShow();
    console.log(this.selectedMonth);
    
    // console.log(moment(this.selectedMonth).endOf('month'));
    // console.log(moment(this.selectedMonth).startOf('month'));
    let startDate = moment(this.selectedMonth).startOf('month').format('YYYY-MM-DD 00:mm:ss');
    let endDate =  moment(this.selectedMonth).endOf('month').format('YYYY-MM-DD hh:mm:ss');
    let param = {
      startDate:startDate,
      endDate:endDate
    }
    this.reportService.getDasboardCount(param).subscribe(res=>{
     // console.log(res);
      if(res){
        this.totalRecharge = res.recharge;
        this.totalPayment = res.payment;
      
      }
      this.initService.loaderHide();
      
    },
    
    (err)=>{
      this.initService.loaderHide();
    })

    this.service.walletAmount().subscribe(res=>{
      this.balance = res.wallet
    })
    
    
  }

}
