import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
    HttpClientModule,
    HttpInterceptor,
    HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
    LocationStrategy,
    HashLocationStrategy,
    PathLocationStrategy,
} from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { HomeComponent } from "./components/home/home.component";
import { ButtonModule } from "primeng/button";
import { TooltipModule } from "primeng/tooltip";
import { RadioButtonModule } from "primeng/radiobutton";
import { InputSwitchModule } from "primeng/inputswitch";

import { CarService } from "./service/carservice";
import { CountryService } from "./service/countryservice";
import { EventService } from "./service/eventservice";
import { NodeService } from "./service/nodeservice";

import { IconService } from "./service/iconservice";
import { CustomerService } from "./service/customerservice";
import { PhotoService } from "./service/photoservice";
import { VersionService } from "./service/versionservice";
import { AppConfigService } from "./service/appconfigservice";
import { ProductService } from "./service/productservice";

import { AppTopBarComponent } from "./app.topbar.component";
import { AppMenuComponent } from "./app.menu.component";
import { AppConfigComponent } from "./app.config.component";
import { AppFooterComponent } from "./app.footer.component";
import { AppInputStyleSwitchModule } from "./app.inputstyleswitch.component";

import { GraphQLModule } from "./graphql.module";

import { TableModule } from "../components/table/table";
import { CalendarModule } from "../components/calendar/calendar";
import { ConfirmDialogModule } from "../components/confirmdialog/confirmdialog";
import { DialogModule } from "../components/dialog/dialog";
import { MultiSelectModule } from "../components/multiselect/multiselect";
import { SliderModule } from "../components/slider/slider";
import { DropdownModule } from "../components/dropdown/dropdown";
import { ToastModule } from "../components/toast/toast";
import { InputTextModule } from "../components/inputtext/inputtext";
import { InputTextareaModule } from "../components/inputtextarea/inputtextarea";
import { ProgressBarModule } from "../components/progressbar/progressbar";
import { ToolbarModule } from "../components/toolbar/toolbar";
import { FileUploadModule } from "../components/fileupload/fileupload";
import { TabViewModule } from "../components/tabview/tabview";
import { InputNumberModule } from "../components/inputnumber/inputnumber";
import { PaginatorModule } from "../components/paginator/paginator";

import { MenubarModule } from "../components/menubar/menubar";
import { DividerModule } from "../components/divider/divider";
import { LoaderComponent } from "./components/models/loader/loader.component";
import { ProgressSpinnerModule } from "../components/progressspinner/progressspinner";
import { SplitButtonModule } from "../components/splitbutton/splitbutton";
import { PanelModule } from "../components/panel/panel";
import { BrowserModule } from "@angular/platform-browser";
import { ToastrModule } from "ngx-toastr";
import { LoginComponent } from "./components/models/login/login.component";

import { SettingsComponent } from "./components/settings/settings.component";

import { AuthInterceptor } from "./auth.interceptor";
import { CardModule } from "../components/card/card";
import { ToastNotificationsModule } from "ngx-toast-notifications";
import { TipsComponent } from "./components/models/tips/tips.component";
import { ProjectsComponent } from "./components/models/projects/projects.component";
import { BlogService } from "./service/blog.service";
import { EditorModule } from "../components/editor/editor";
import { ChipsModule } from "../components/chips/chips";
import {GalleriaModule} from '../components/galleria/galleria';
import { ProjectGalleryComponent } from "./components/models/project-gallery/project-gallery.component";
import { AuthGuard } from "../shared/guards/auth.guard";
import { DashboardComponent } from "./components/models/dashboard/dashboard.component";
import { EmployeesComponent } from "./components/models/employees/employees.component";
import { ReportComponent } from "./components/models/report/report.component";
import { AvatarModule } from "../components/avatar/avatar";
import { AvatarGroupModule } from "../components/avatargroup/avatargroup";
import { TagModule } from "../components/tag/tag";
import { ChipModule } from "../components/chip/chip";


@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        SettingsComponent,
        AppTopBarComponent,
        AppMenuComponent,
        AppConfigComponent,
        AppFooterComponent,
        LoaderComponent,
        LoginComponent,
        ProjectsComponent,
        TipsComponent,
        ProjectGalleryComponent,
        DashboardComponent,
        EmployeesComponent,
        ReportComponent


    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ButtonModule,
        RadioButtonModule,
        InputSwitchModule,
        TooltipModule,
        AppInputStyleSwitchModule,
        GraphQLModule,
        TableModule,
        CalendarModule,
        SliderModule,
        DialogModule,
        ConfirmDialogModule,
        MultiSelectModule,
        DropdownModule,
        ToastModule,
        InputTextModule,
        InputNumberModule,
        InputTextareaModule,
        ProgressBarModule,
        ToolbarModule,
        FileUploadModule,
        TabViewModule,
        PaginatorModule,
        DividerModule,
        MenubarModule,
        ProgressSpinnerModule,
        SplitButtonModule,
        PanelModule,
        BrowserModule,
        BrowserAnimationsModule,
        CardModule,
        EditorModule,
        ChipsModule,
        ToastNotificationsModule,
        GalleriaModule,
        ToastrModule.forRoot(),
        AvatarModule,
        AvatarGroupModule,
        TagModule,ChipModule   
    ],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        CarService,
        CountryService,
        EventService,
        NodeService,
        IconService,
        CustomerService,
        PhotoService,
        VersionService,
        AppConfigService,
        ProductService,
        BlogService,
        AuthGuard
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
