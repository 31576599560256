import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class TeacherService {
    createTeacherQuery = gql`
    mutation ($address: String, $caste: String, $designation: String, $emailId: String, $gender: String, $name: String, $phone: String, $subject: String, $whatsapp: String, $schoolid: ID) {
        Teacher(address: $address, caste: $caste, designation: $designation, emailId: $emailId, gender: $gender, name: $name, phone: $phone, subject: $subject, whatsapp: $whatsapp, schoolid: $schoolid) {
          mesg
        }
      }

    `;

    _updateTeacherQuery = gql`
    mutation ($address: String, $caste: String, $designation: String, $emailId: String, $gender: String, $name: String, $phone: String, $subject: String, $whatsapp: String, $schoolid: ID,$teacherid:ID) {
        editTeacher(address: $address, caste: $caste, designation: $designation, emailId: $emailId, gender: $gender, name: $name, phone: $phone, subject: $subject, whatsapp: $whatsapp, schoolid: $schoolid,teacherid:$teacherid) {
          mesgsuccess
        }
      }


    `;

   getTeachers = gql`
   query{
    allTeacherView{
      id
      name
      designation
      gender
      subject
      caste
      phone
      whatsapp
      emailId
      address
      school{
        id
        schoolName
      }


    }
  }
   `;

   _deleteTeacher = gql`
   mutation ($teacherid:ID){
    deleteTeacher(teacherid:$teacherid){
         ok
       }
     }

   `;


  constructor(private apollo:Apollo) { }


  loadTeachers(){
    return this.apollo.mutate({
        mutation: this.getTeachers,


      })
}

createTeacher(data:any){
    return this.apollo.mutate({
        mutation: this.createTeacherQuery,
        variables: data,

      })
}
updateTeacher(data:any){
    return this.apollo.mutate({
        mutation: this._updateTeacherQuery,
        variables: data,

      })
}

deleteTeacher(id:any){
    let data = {
         "teacherid":id
       }
     return this.apollo.mutate({
         mutation:this._deleteTeacher,
         variables: data,
         errorPolicy:'all'
     })
 }
}
