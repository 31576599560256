import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { HomeComponent } from './components/home/home.component';

// import { UsersComponent } from './components/models/users/users.component';
import { LoginComponent } from './components/models/login/login.component';

import { SettingsComponent } from './components/settings/settings.component';

import { TipsComponent } from './components/models/tips/tips.component';
import { ProjectsComponent } from './components/models/projects/projects.component';
import { ProjectGalleryComponent } from './components/models/project-gallery/project-gallery.component';
import { AuthGuard } from '../shared/guards/auth.guard';
import { DashboardComponent } from './components/models/dashboard/dashboard.component';
import { EmployeesComponent } from './components/models/employees/employees.component';
import { ReportComponent } from './components/models/report/report.component';


@NgModule({
    imports: [
        RouterModule.forRoot([
            {path: '', redirectTo: 'dashboard', pathMatch: 'full',canActivate: [AuthGuard]},

            {path: 'login', component: LoginComponent},
            {path: 'dashboard', component: DashboardComponent,canActivate: [AuthGuard]},
            {path: 'employees', component: EmployeesComponent,canActivate: [AuthGuard]},
            // {path: 'employee-detail/:id', component: ProjectGalleryComponent,canActivate: [AuthGuard]},
            {path:'reports',component:ReportComponent,canActivate: [AuthGuard]},
            {path:'settings',component:SettingsComponent,canActivate: [AuthGuard]}


        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
