<div class="container ">
  
   <div class="settings-conatiner">
    <h4>Update Password</h4>
    <div class="p-fluid p-grid">
        <div class="p-field p-col-12 p-md-12">
          <span class="p-float-label">
            <input type="password" [(ngModel)]="password" id="inputtext" pInputText  />
            <label for="inputtext">Password</label>
          </span>
        </div>
        <div class="p-field p-col-12 p-md-12">
            <span class="p-float-label">
              <input type="password" [(ngModel)]="cpassword" id="inputtext" pInputText  />
              <label for="inputtext">Confirm Password</label>
            </span>
          </div>
       
      
         
      </div>
      <div class="button-container">
        <button pButton type="button" (click)="updatePassword()" [disabled]="isDisabled()" label="Update" class="pr-1" ></button>
        <button pButton type="button" [routerLink]="'/'" label="Cancel" ></button>
      </div>
   </div>
</div>
  