import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { MessageService } from 'primeng/api';
import { DivisionService } from 'src/app/showcase/service/division.service';
import { InitService } from 'src/app/showcase/service/init.service';
import { saveAs } from "file-saver";
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'primeng/api';
export class CsvData {
  public id: any;
  public amount: any;
}
@Component({
  selector: 'employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.css'],
  providers: [MessageService]
})
export class EmployeesComponent implements OnInit {
  isDisplay = false;
  rechargeModel = false
  isUpdate = false;
  pagination = {
    page: 1,
    limit: 10,
    total:0
};
response:any = ""
rechargeFile:any=""
searchText = ""
  public employeeForm: FormGroup;
    division: any[];

    productDialog = false;
    first = 0;

    rows = 10;
    selectedProducts;
  employeesList: any = [];
  selectedEmployee: any;
  records: any = [];
  userRole = "";
  isPasswordReset: boolean;
  resetPassword:any = "";
    constructor(
      private employeeService: DivisionService,
       private fb: FormBuilder,
       private initService:InitService,
       private messageService: MessageService
       //private messageService: MessageService
       ) {
if(sessionStorage.getItem('userRole')){
  this.userRole = sessionStorage.getItem('userRole');
}
        }

    ngOnInit(): void {
      this.employeeForm  = this.fb.group({
        name:['',Validators.required],
        phone:['',Validators.required],
        employeId:['',Validators.required],
        
      })
        this.loadEmployees();
       

    }


    search(){
      this.searchText = this.searchText.trim();
      if(this.searchText != "" && this.searchText.length > 3){

        this.initService.loaderShow();
      
        
       
        this.employeeService.searchEmployee(this.searchText).subscribe(res => {
            this.initService.loaderHide();
            this.employeesList = [];
            this.employeesList.push(res);
            //this.pagination.total = res.total;
        }, (error) => {
          this.initService.loaderHide();
            // console.log('there was an error sending the query', error);
        });
      }
    }
  
    downloadReport(){
      this.employeeService.employeeReport().subscribe(res=>{
        saveAs(res, "employee.xlsx");
        console.log(res);
        
      })
    }


    loadEmployees() {
        this.initService.loaderShow();
        let param = {
          page: this.pagination.page,
          limit: this.pagination.limit,
      };
        this.employeeService.loadEmployees(param).subscribe(res => {
            this.initService.loaderHide();
            this.employeesList = res.data;
            this.pagination.total = res.total;
        }, (error) => {
            // console.log('there was an error sending the query', error);
        });
    }

    onSave(){
      if(this.employeeForm.valid){
        if(this.isUpdate){
          this.employeeService.updateEmployee(this.employeeForm.value,this.selectedEmployee).subscribe(res=>{
  
          if(res.status === 'success'){
            this.isUpdate = false;
            this.isDisplay = false;
            this.employeeForm.reset();
          //   this.messageService.add({
          //     severity: "success",
          //     summary: "Record Update Successfully",
          //     detail: "Message Content",
          // });
          this.loadEmployees();
          }
          else{
          //   this.messageService.add({
          //     severity: "error",
          //     summary: "Record not been updated Successfully",
          //     detail: "Message Content",
          // });
          }
          
  
          })
  
        }
        else{
          let param = {
            name:this.employeeForm.value.name,
            status:1,
            password:"123",
            phone:this.employeeForm.value.phone,
            wallet:0,
            employeId:this.employeeForm.value.employeId
          }
          this.employeeService.saveEmployee(param).subscribe(res=>{
            if(res.status === 'success'){
              this.isDisplay = false;
              this.employeeForm.reset();
            //   this.messageService.add({
            //     severity: "success",
            //     summary: "Record Update Successfully",
            //     detail: "Message Content",
            // });
            this.loadEmployees();
            }
            else{
            //   this.messageService.add({
            //     severity: "error",
            //     summary: "Record not been updated Successfully",
            //     detail: "Message Content",
            // });
            }
          })
        }

      }
    
    }

    action(details) {
      return [
  
          {
              label: "Update",
              icon: "pi pi-pencil",
              command: () => {
                  this.updateEmployee(details);
                   this.isDisplay = true;
              },
          },
          {
            label: "Reset Password",
            icon: "pi pi-pencil",
            command: () => {
                this.selectedEmployee= details.employeId
                 this.isPasswordReset = true;
            },
        },
          {
              label: "Delete",
              icon: "pi pi-times",
              command: () => {
                 this.deleteEmployee(details.id);
              },
          },
      ];
  }


  deleteEmployee(id){
if(id != ""){
  this.employeeService.deleteEmployee(id).subscribe(res=>{
    if(res.status === 'success'){
    //   this.messageService.add({
    //     severity: "success",
    //     summary: "Record Update Successfully",
    //     detail: "Message Content",
    // });
    this.loadEmployees();
    }
    else{
    //   this.messageService.add({
    //     severity: "error",
    //     summary: "Record not been updated Successfully",
    //     detail: "Message Content",
    // });
    }


})
  }
}

  onPageChange(e){
    console.log(e);
    this.pagination.page = e.page+1;
    this.pagination.limit = e.rows;
    this.loadEmployees();

}

openNew(){
  this.isUpdate = false;
  this.isDisplay = true;
}
updateEmployee(empDetails){
  this.isUpdate = true;
  this.selectedEmployee = empDetails.id;
  this.employeeForm.get('name').setValue(empDetails.name);
  this.employeeForm.get('phone').setValue(empDetails.phone);
  this.employeeForm.get('employeId').setValue(empDetails.employeId);
}

onUpload(e){
  let input = e.target;
  let reader = new FileReader();
  reader.readAsText(input.files[0]);

  reader.onload = () => {
    let csvData = reader.result;
    let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);

    let headersRow = this.getHeaderArray(csvRecordsArray);

    this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
  };

  reader.onerror = function () {
    console.log('error is occured while reading file!');
  };
}

getHeaderArray(csvRecordsArr: any) {
  let headers = (<string>csvRecordsArr[0]).split(',');
  let headerArray = [];
  for (let j = 0; j < headers.length; j++) {
    headerArray.push(headers[j]);
  }
  return headerArray;
}


getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
  let csvArr = [];

  for (let i = 1; i < csvRecordsArray.length; i++) {
    let curruntRecord = (<string>csvRecordsArray[i]).split(',');
    if (curruntRecord.length == headerLength) {
      let csvRecord: CsvData = new CsvData();
      csvRecord.id = curruntRecord[0].trim();
      csvRecord.amount = curruntRecord[1].trim();
      csvArr.push(csvRecord);
    }
  }
  return csvArr;
}


rechargeDataRecords(){
  if(this.records.length > 0){
   let  param = {
      "data":this.records,
      "user":"admin"
    }
    this.employeeService.rechargeAmount(param).subscribe(res=>{
    console.log(res);
    this.records = [];
    this.response = res
    this.rechargeFile = ""

    })
  }
}

closeRecharge(){
  this.rechargeModel = false;
  this.records = [];
  this.response = [];
  this.rechargeFile = ""
}

updateEmpPassword(){
if(this.selectedEmployee == '' && this.resetPassword == ''){
return;
}
let param = {
  "password" : this.resetPassword,
  "updatedAt":moment()

}

this.initService.loaderShow();
  this.employeeService.updateEmployee(param,this.selectedEmployee).subscribe(res=>{
   if(res.status === 'success'){
    this.resetPassword = "",
    this.selectedEmployee = "",
    this.isPasswordReset = false
   }
   this.initService.loaderHide();
  //  this.toastrService.success('Updated Successfully','Success', {
  //       timeOut: 3000,
  //     });
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Updated Successfully' });
  },
  (err)=>{
    this.initService.loaderHide();
    this.messageService.add({ severity: 'error', summary: 'Warning', detail: 'Please try again later.' });
  }
  )

}

}
