<loader></loader>
<div class="card">
    <h5>Project List</h5>
    <p-toolbar styleClass="p-mb-4">
        <ng-template pTemplate="left">
            <button
                pButton
                pRipple
                label="New"
                icon="pi pi-plus"
                class="p-button-success p-mr-2"
                (click)="isDisplay = true"
            ></button>
        </ng-template>
    </p-toolbar>

    <p-table
        #dt1
        [value]="projectList"


        [showCurrentPageReport]="true"

        [globalFilterFields]="['name,school.schoolName,']"
    >

        <ng-template pTemplate="header">
            <tr>
                <th  style="width: 60%">Project Title
                    <p-columnFilter type="text" field="title" display="menu"></p-columnFilter>
                </th>
                <th>Banner</th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <td class="format-text">{{ item.title }}</td>
                <td>
                    <img
                        [src]="getURL(item.banner)"
                        [alt]="item.title"
                        width="100"
                        class="shadow-4"
                    />
                </td>

                <td>
                    <p-splitButton
                        label="Actions"
                        [model]="action(item)"
                    ></p-splitButton>
                    <!-- <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editProduct(item)"></button>
                    <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteProduct(item)"></button> -->
                </td>
            </tr>
        </ng-template>



    </p-table>
    <p-paginator (onPageChange)="onPageChange($event)" [first]="1" [rows]="10" [totalRecords]="pagination.total" [rowsPerPageOptions]="[10, 20, 30]"></p-paginator>
    <p-dialog
        [(visible)]="isDisplay"

        [header]="isUpdate == true ? 'Update Project' : 'Create Project'"

        styleClass="p-fluid"
    >
        <form [formGroup]="projectForm" (submit)="saveProject()">
            <div class="p-grid">
                <div class="p-field p-md-12 p-sm-12">
                    <label for="name">Project Title</label>
                    <input
                        type="text"
                        pInputText
                        id="name"
                        formControlName="title"
                        required
                        autofocus
                    />
                    <!-- <small class="p-invalid" *ngIf="submitted && !product.name">Name is required.</small> -->
                </div>

                <div class="p-field p-md-6 p-sm-12">
                    <label for="name">Banner</label>
                   <div> <input
                    type="file"
                    name="myfile"
                    (change)="uploadBanner($event)"
                /></div>
                    <!-- <small class="p-invalid" *ngIf="submitted && !product.name">Name is required.</small> -->
                    <div  *ngIf="projectForm.get('banner')?.value">
                        <img

                            [src]="getURL(projectForm.get('banner').value)"
                            width="40px"
                        />
                    </div>
                </div>

                <div class="p-field p-md-6 p-sm-12">
                    <label for="name">Project Images</label>

                   <div>
                    <input
                    multiple
                    type="file"
                    name="myfile"
                    (change)="uploadImages($event)"
                />
                   </div>
                    <!-- <small class="p-invalid" *ngIf="submitted && !product.name">Name is required.</small> -->
                    <div  *ngIf="projectImagesList.length">
                        <img
                            *ngFor="let image of projectImagesList"
                            [src]="getURL(image)"
                            width="40px"
                        />
                    </div>
                </div>

                <div class="p-field p-md-4 p-sm-12 p-md-offset-4">
                    <button
                        pButton
                        pRipple
                        [disabled]="!projectForm.valid"
                        label="Save"
                        type="submit"
                        class="p-button-primary"
                    ></button>
                </div>
            </div>
        </form>
    </p-dialog>
</div>

<p-toast position="top-right"></p-toast>
