import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { BlogService } from 'src/app/showcase/service/blog.service';
import { InitService } from 'src/app/showcase/service/init.service';
import { ProjectService } from 'src/app/showcase/service/project.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.css'],
  providers: [MessageService]
})
export class TipsComponent implements OnInit {
    pagination = {
        page: 1,
        limit: 10,
        total:0
    };
    submitted= false;
    description1:any = "";
    description2:any = "";
    tags:any = ""
    isUpdate: boolean = false;
    selectedBlog:any;
    tagList: any;

  constructor(
      private blogService:BlogService,
      private projectService:ProjectService,
      private fb:FormBuilder,
      private messageService: MessageService,
      private initService:InitService,
  ) { }
  isDisplay:Boolean = false;
  public blogForm: FormGroup;
  tipsList: any = [];
  ngOnInit(): void {
      this.blogForm  = this.fb.group({
        name:['',Validators.required],
        banner:['',Validators.required],
        description1:['',Validators.required],
        description2:['',Validators.required],
      })

    this.fetchBlogs();
  }


  fetchBlogs(){
    this.initService.loaderShow();
    let param = {
        page: this.pagination.page,
        limit: this.pagination.limit,
    };
    this.blogService.getAllBlogs(param).subscribe((res) => {
        this.tipsList = res?.data;
        this.pagination.total = res.total
        this.initService.loaderHide();
    });
}


updateBlog(blogDetails){
    this.isUpdate = true;
    this.selectedBlog = blogDetails.id;
    this.blogForm.get('name').setValue(blogDetails.name);
    this.blogForm.get('banner').setValue(blogDetails.banner);
    this.tagList = blogDetails.tags;
    this.tags = blogDetails.tags.map(item=> item.name);
    this.description1 = blogDetails.description1;
    this.description2 = blogDetails.description2;
}

deleteBlog(blogId){
    this.initService.loaderShow();
    this.blogService.deleteBlog(blogId).subscribe(res=>{
        this.messageService.add({
            severity: "danger",
            summary: "Blog Deleted Successfully",
            detail: "Message Content",
        });

        this.fetchBlogs();
        this.initService.loaderHide();
    })

}

createTags(blogId){
    this.initService.loaderShow();
    let formData = new FormArray([]);
    this.tags.forEach((tag) => {
        formData.push(
            this.fb.group({
                name: tag,
                blogId: blogId,
            })
        );
    });
    this.blogService.createTags(formData.value).subscribe(res=>{
        // this.messageService.add({
        //     severity: "danger",
        //     summary: "Blog Deleted Successfully",
        //     detail: "Message Content",
        // });

        this.fetchBlogs();
        this.initService.loaderHide();
    })

}

action(details) {
    return [

        {
            label: "Update Blog",
            icon: "pi pi-pencil",
            command: () => {
                this.updateBlog(details);
                 this.isDisplay = true;
            },
        },
        {
            label: "Delete",
            icon: "pi pi-times",
            command: () => {
                this.deleteBlog(details.id);
            },
        },
    ];
}

onPageChange(e){
    console.log(e);
    this.pagination.page = e.page+1;
    this.pagination.limit = e.rows;
    this.fetchBlogs();

}

saveBlog(){
    this.initService.loaderShow();
    this.blogForm.get('description1').setValue(this.description1);
    this.blogForm.get('description2').setValue(this.description2);

    if(this.isUpdate){
        this.createTags(this.selectedBlog);
        this.blogService.updateBlog(this.blogForm.value,this.selectedBlog).subscribe(res=>{
            this.isDisplay = false;
            this.isUpdate = false;
            this.blogForm.reset();
            this.description1 = "";
            this.description2 = ""
            this.messageService.add({
                severity: "success",
                summary: "Blog Update Successfully",
                detail: "Message Content",
            });
            this.fetchBlogs();

        })
    }
    else if(!this.isUpdate){


        this.blogService.saveBlog(this.blogForm.value).subscribe(res=>{
            this.createTags(res?.id)
            this.isDisplay = false;
            this.blogForm.reset();
            this.description1 = "";
            this.description2 = ""
            this.messageService.add({
                severity: "success",
                summary: "Blog Added Successfully",
                detail: "Message Content",
            });
            this.fetchBlogs();

        })
    }




}

uploadBanner(event) {
    this.initService.loaderShow();
    console.log(event.target.files);
    let formData: FormData = new FormData();

    formData.append("myFile", event.target.files[0]);
    this.projectService.uploadFiles(formData).subscribe((res) => {
        this.initService.loaderHide();
        this.messageService.add({
            severity: "success",
            summary: "Banner uploaded successfully",
            detail: "Message Content",
        });

        this.blogForm.get("banner").setValue(res[0].path);

    },
    (err)=>{
        this.initService.loaderHide();
    });
}

getURL(imageurl){
   return environment.url+imageurl;
}
removeTag(tag){

    let tagId = this.tagList.filter(ptag => ptag.name === tag);
    if(tagId.length == 1){
        this.initService.loaderShow();
        this.projectService.deleteTag(tagId[0]?.id).subscribe(res=>{
            this.messageService.add({
                severity: "success",
                summary: "Tag Deleted Successfully",
                detail: "Message Content",
            });

            this.fetchBlogs();
            this.initService.loaderHide();
        })
    }


}

}
