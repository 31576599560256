<p-toolbar styleClass="p-mb-4 container">
    <ng-template pTemplate="left">
      
    </ng-template>
    <ng-template pTemplate="right">
        <p-calendar placeholder="Select Month" [maxDate]="maxMonth" [(ngModel)]="selectedMonth" view="month" dateFormat="mm/yy" [readonlyInput]="true"></p-calendar>
        <button
        type="button"
        pButton
        pRipple
      [disabled]="selectedMonth==''"
        icon="pi pi-search"
       (click)="getCount()"
        class="p-button-success mr-2"
        pTooltip="Search"
        tooltipPosition="bottom"
    ></button>
    </ng-template>
</p-toolbar>




<div class="p-fluid p-grid container card-section">
    <p-card header="Total Recharge" class="p-col-4 p-md-4 text-center">
        <p class="m-0">
            {{(totalRecharge? totalRecharge: 0) | currency:"INR"}}
        </p>
    </p-card>
    <p-card header="Total Payments" class="p-col-4 p-md-4 text-center">
        <p class="m-0">
            {{(totalPayment ? totalPayment : 0 )| currency:"INR"}}
        </p>
    </p-card>
    <p-card header="Total Wallet Balance" class="p-col-4 p-md-4 text-center">
        <p class="m-0">
           {{(balance ? balance: 0) | currency:"INR"}}
        </p>
    </p-card>
</div>
