import { Component, OnInit } from '@angular/core';
import { InitService } from 'src/app/showcase/service/init.service';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {


  public isLoading = false;
  constructor(private initService:InitService) { }

  ngOnInit(): void {


    this.initService.getLoader().subscribe((resp:any)=>{
      this.isLoading = resp;
     

    })
  }

}
