
<div class="home">
    <div class="container mt-20">
        <div  style=" margin-top: 20px !important;
        margin-bottom: 20px !important;">
            <!-- <button pButton type="button" label="New Contact"></button> -->
            <!-- <button pButton type="button" (click)="showPayment()" label="Actions"></button> -->
<label>Current Financial Year <strong>{{currentFYear}}-{{currentFYear+1}}</strong><i><a routerLink="/settings"> Change</a></i></label>

    </div>
    </div>
    <div class="features">
        <div class="p-grid">
            <div class="p-col-12 p-md-4" *ngIf="userType == 'admin'">
                <div class="feature-card">
                    <a [routerLink]="['school-list']" class="header-section">
                        <h4 class="header-title">
                            {{ schools?.length ? schools?.length : 0 }}
                        </h4>
                        <div class="feature-card-detail">
                            <div class="feature-name">Schools</div>
                            <!-- <p>Don’t get tied up in just one look&feel. Choose from a variety of options including material and bootstrap design.</p> -->
                        </div>
                    </a>
                </div>
            </div>
            <div class="p-col-12 p-md-4" *ngIf="userType == 'admin'">
                <div class="feature-card">
                    <a [routerLink]="['teachers-list']" class="header-section">
                        <h4 class="header-title">
                            {{ teacherList?.length ? teacherList?.length : 0 }}
                        </h4>
                        <div class="feature-card-detail">
                            <div class="feature-name">Teachers</div>
                            <!-- <p>Don’t get tied up in just one look&feel. Choose from a variety of options including material and bootstrap design.</p> -->
                        </div>
                    </a>
                </div>
            </div>
            <div class="p-col-12 p-md-4" *ngIf="userType == 'admin'">
                <div class="feature-card">
                    <a [routerLink]="['school-block']" class="header-section">
                        <h4 class="header-title">
                            {{
                                divisionList?.length ? divisionList?.length : 0
                            }}
                        </h4>
                        <div class="feature-card-detail">
                            <div class="feature-name">Block / Circle</div>
                            <!-- <p>Don’t get tied up in just one look&feel. Choose from a variety of options including material and bootstrap design.</p> -->
                        </div>
                    </a>
                </div>
            </div>
            <div class="p-col-12 p-md-8" *ngIf="userType == 'admin'">
                <div class="feature-card">
                    <div class="card-header-section">
                        <p-header>School's List</p-header>
                    </div>
                    <p-table
                        [value]="schools"
                        scrollHeight="200px"
                        [scrollable]="true"
                        [paginator]="true"
                        [rows]="10"
                        [showCurrentPageReport]="true"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [rowsPerPageOptions]="[10, 25, 50]"
                    >
                        <ng-template pTemplate="header">
                            <tr>
                                <th>School Name</th>
                                <th>Dice Code</th>
                                <th>Contact</th>
                                <th>Email</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td>{{ item.schoolName }}</td>
                                <td>{{ item.diseCode.newUdiseCode }}</td>
                                <td>{{ item.schoolContact }}</td>
                                <td>{{ item.schoolEmail }}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <div class="p-col-12 p-md-4" *ngIf="userType == 'admin'">
                <div class="feature-card">
                    <div class="card-header-section">
                        <p-header>Block List</p-header>
                    </div>


                    <p-table
                        [value]="divisionList"
                        scrollHeight="200px"
                        [scrollable]="true"
                        [paginator]="true"
                        [rows]="10"
                        [showCurrentPageReport]="true"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [rowsPerPageOptions]="[10, 25, 50]"
                    >
                        <ng-template pTemplate="header">
                            <tr>
                                <th>
                                    Block Name
                                    <p-columnFilter
                                        type="text"
                                        field="blockName"
                                        display="menu"
                                    ></p-columnFilter>
                                </th>
                                <th>
                                    Circle Name
                                    <p-columnFilter
                                        type="text"
                                        field="circleName"
                                        display="menu"
                                    ></p-columnFilter>
                                </th>
                                <th>Old UDISE</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td>{{ item.blockName }}</td>
                                <td>{{ item.circleName }}</td>

                                <td>{{ item.newUdiseCode }}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>

            <div class="p-col-12 p-md-12" *ngIf="userType == 'admin'">
                <div class="feature-card">
                    <div class="card-header-section">
                    <p-header>Teacher's List</p-header>
                    </div>
                    <p-table
                        [value]="teacherList"
                        scrollHeight="200px"
                        [scrollable]="true"
                        [paginator]="true"
                        [rows]="10"
                        [showCurrentPageReport]="true"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [rowsPerPageOptions]="[10, 25, 50]"
                    >
                        <ng-template pTemplate="header">
                            <tr>
                                <th>
                                    Name
                                    <p-columnFilter
                                        type="text"
                                        field="name"
                                        display="menu"
                                    ></p-columnFilter>
                                </th>
                                <th width="20%">
                                    School Name
                                    <p-columnFilter
                                        type="text"
                                        field="school.schoolName"
                                        display="menu"
                                    ></p-columnFilter>
                                </th>
                                <th>Designation</th>
                                <th>Contact</th>
                                <th>Email</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td>{{ item.name }}</td>
                                <td>{{ item.school.schoolName }}</td>
                                <td>{{ item.designation }}</td>
                                <td>{{ item.phone }}</td>
                                <td>{{ item.emailId }}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>

            <div class="p-col-12 p-md-8" *ngIf="userType == 'school'">
                <div class="feature-card">
                    <div class="card-header-section">
                    <p-header>Teacher's List</p-header>
                    </div>
                    <p-table
                        [value]="teacherList"
                        scrollHeight="200px"
                        [scrollable]="true"
                        [paginator]="true"
                        [rows]="10"
                        [showCurrentPageReport]="true"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [rowsPerPageOptions]="[10, 25, 50]"
                    >
                        <ng-template pTemplate="header">
                            <tr>
                                <th>
                                    Name
                                    <p-columnFilter
                                        type="text"
                                        field="name"
                                        display="menu"
                                    ></p-columnFilter>
                                </th>

                                <th>Designation</th>
                                <th>Contact</th>
                                <th>Email</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td>{{ item.name }}</td>

                                <td>{{ item.designation }}</td>
                                <td>{{ item.phone }}</td>
                                <td>{{ item.emailId }}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>

            <div class="p-col-12 p-md-4" *ngIf="userType == 'school'">
                <div class="feature-card">
                    <div class="card-header-section">
                    <p-header>Notice List</p-header>
                    </div>

                    <p-table
                        [value]="noticeList"
                        scrollHeight="200px"
                        [scrollable]="true"
                        [paginator]="true"
                        [rows]="10"
                        [showCurrentPageReport]="true"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [rowsPerPageOptions]="[10, 25, 50]"
                    >
                        <ng-template pTemplate="header">
                            <tr>
                                <th>
                                    Date
                                    <p-columnFilter
                                        type="text"
                                        field="blockName"
                                        display="menu"
                                    ></p-columnFilter>
                                </th>
                                <th>
                                    Message

                                </th>

                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td>{{ item?.blockName }}</td>
                                <td>{{ item?.circleName }}</td>


                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>
