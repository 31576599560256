import { Component, OnInit } from '@angular/core';
import { BlogService } from '../../service/blog.service';
import { DivisionService } from '../../service/division.service';
import { Toaster } from 'ngx-toast-notifications';

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

   
    password = "";
    cpassword = "";
    userId = "";
  constructor(
    private service:DivisionService,
    private toaster:Toaster
  ) {
    if(sessionStorage.getItem('userID')){
      this.userId = sessionStorage.getItem('userID');
    }
   }

  ngOnInit(): void {

   
  }
  isDisabled(){
 
   
   
  }
  updatePassword(){
    if(this.password == this.cpassword && this.password != "" && this.cpassword != ""){
      let param = {
        password: this.password,
        updatedAt: new Date()
      }
      this.service.updateUser(param,this.userId).subscribe(res=>{
        this.toaster.open({text:"Password Updated Successfully",position:"top-right"});
        this.password = "";
        this.cpassword= "";
      },
      
      (error)=>{
        this.toaster.open({text:"Error Occured ! Please try again later",position:"top-right"});
      })
    }
  }
}
