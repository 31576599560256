import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { InitService } from "src/app/showcase/service/init.service";
import { ProjectService } from "src/app/showcase/service/project.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "projects",
    templateUrl: "./projects.component.html",
    styleUrls: ["./projects.component.css"],
    providers: [MessageService],
})
export class ProjectsComponent implements OnInit {
    projectList: any;
    isDisplay: boolean = false;
    projectImagesList: any = [];
    public projectForm: FormGroup;
    isUpdate: boolean = false;
    selectedProject:any;
    constructor(
        private projectService: ProjectService,
        private formBuilder: FormBuilder,
        private router: Router,
        private messageService: MessageService,
        private initService:InitService
    ) {}

    pagination = {
        page: 1,
        limit: 10,
        total:0
    };

    ngOnInit(): void {
        this.projectForm = this.formBuilder.group({
            title: ["", Validators.required],
            banner: ["", Validators.required],
        });
        this.fetchProjects();
    }

    onPageChange(e){
        console.log(e);
        this.pagination.page = e.page+1;
        this.pagination.limit = e.rows;
        this.fetchProjects()

    }

    fetchProjects() {
        this.initService.loaderShow();
        let param = {
            page: this.pagination.page,
            limit: this.pagination.limit,

        };
        this.projectService.getAllProjects(param).subscribe((res) => {
            this.projectList = res?.data;
            this.pagination.total = res.total
            this.initService.loaderHide();
        });
    }

    openNew() {}

    saveProject() {

        this.initService.loaderShow();
        if(this.isUpdate && this.projectForm.valid){
            this.projectService
            .updateProject(this.projectForm.value,this.selectedProject.id)
            .subscribe((res: any) => {
                this.isUpdate = false;
                this.isDisplay = false;
                this.initService.loaderHide();
                if(this.projectImagesList.length){
                    this.saveProjectImages(this.selectedProject.id);
                }
                else{
                    this.projectForm.reset();
                    this.fetchProjects();
                }

            });
        }
        if(!this.isUpdate && this.projectForm.valid){
            this.projectService
            .saveProject(this.projectForm.value)
            .subscribe((res: any) => {
                this.initService.loaderHide();
                this.saveProjectImages(res?.id);
            });
        }

    }

    saveProjectImages(projectId) {
        let formData = new FormArray([]);
        this.initService.loaderShow();
        this.projectImagesList.forEach((image) => {
            formData.push(
                this.formBuilder.group({
                    filePath: image,
                    projectId: projectId,
                })
            );
        });

        this.projectService
            .saveProjectImages(formData.value)
            .subscribe((res) => {
                this.messageService.add({
                    severity: "success",
                    summary: "Project Added Successfully",

                });
                this.isDisplay = false;
                this.initService.loaderHide();
                this.fetchProjects();
            });
    }

    updateProject(projectDetails) {
        this.selectedProject = projectDetails
        this.projectForm.get('title').setValue(projectDetails.title);
        this.projectForm.get('banner').setValue(projectDetails.banner);
    }
    deleteProject(projectId) {
        this.initService.loaderShow();
        this.projectService.deleteProject(projectId).subscribe((res) => {
            this.messageService.add({
                severity: "success",
                summary: "Project deleted successfully",

            });
            this.initService.loaderHide();
            this.fetchProjects();
        });
    }

    action(details) {
        return [
            {
                label: "Edit Gallery",
                icon: "pi pi-pencil",
                command: () => {
                    this.router.navigateByUrl("projects-gallery/" + details.id);
                    // this.initForm(details);
                    // this.isDialog = true;
                },
            },
            {
                label: "Update",
                icon: "pi pi-pencil",
                command: () => {
                    this.updateProject(details);
                    this.isUpdate = true;
                     this.isDisplay = true;
                },
            },
            {
                label: "Detele",
                icon: "pi pi-times",
                command: () => {
                    this.deleteProject(details.id);
                },
            },
        ];
    }
    uploadBanner(event) {
        this.initService.loaderShow();
        let formData: FormData = new FormData();
        formData.append("myFile", event.target.files[0]);
        this.projectService.uploadFiles(formData).subscribe((res) => {
            this.messageService.add({
                severity: "success",
                summary: "Banner Image Uploaded Successfully",

            });
            this.initService.loaderHide();
            this.projectForm.get("banner").setValue(res[0].path);
        },
        (err)=>{
            this.initService.loaderHide();
        });
    }
    uploadImages(event) {
        this.initService.loaderShow();
        let formData: FormData = new FormData();
        for (let i = 0; i < event.target.files.length; i++) {
            formData.append("myFile", event.target.files[i]);
        }

        this.projectService.uploadFiles(formData).subscribe((res) => {
            this.messageService.add({
                severity: "success",
                summary: "Images Uploaded Successfully",

            });
            res.forEach((image) => {
                this.projectImagesList.push(image?.path);
            });
            this.initService.loaderHide();
        });
    }
    getURL(url) {
        return environment.url + url;
    }
}
