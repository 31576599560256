import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DivisionService {
  constructor(private http: HttpClient) {}


  loadEmployees(param): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return this.http.get(
        environment.apiUrl +
            `employee/all?page=${param.page}&limit=${param.limit}`
    );
}

saveEmployee(param): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return this.http.post(
      environment.apiUrl + `employee/new`, param
  );
}

// uploadFiles(param):Observable<any>{
//     return this.http.post(environment.apiUrl + `upload`, param)
// }
updateEmployee(param, empId): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return this.http.put(environment.apiUrl + `employee/${empId}`, param, {
        reportProgress: true,
        responseType: "json",
    });
}

deleteEmployee(param): Observable<any> {
  const httpHeaders = new HttpHeaders();
  httpHeaders.set("Content-Type", "application/json");
  return this.http.delete(environment.apiUrl + `employee/${param}`);
}
// createTags(param): Observable<any> {
//     const httpHeaders = new HttpHeaders();
//     httpHeaders.set("Content-Type", "application/json");
//     return this.http.post(environment.apiUrl + `tags/new`, param, {
//         reportProgress: true,
//         responseType: "json",
//     });
// }
// deleteBlog(param): Observable<any> {
//     const httpHeaders = new HttpHeaders();
//     httpHeaders.set("Content-Type", "application/json");
//     return this.http.delete(environment.apiUrl + `blog/${param}`);
// }

updateUser(param,id): Observable<any> {
  const httpHeaders = new HttpHeaders();
  httpHeaders.set("Content-Type", "application/json");
  return this.http.put(environment.apiUrl + `user/updatePassword/${id}`, param, {
      reportProgress: true,
      responseType: "json",
  });
}

employeeReport(): Observable<any> {
  const httpHeaders = new HttpHeaders();
  httpHeaders.set("Content-Type", "application/json");
  return this.http.post(environment.apiUrl + `getReport/employeeReport`,{}, {
    responseType: "blob",
    headers: new HttpHeaders().append("Content-Type", "application/json")
  });
}

orderReport(param): Observable<any> {
  const httpHeaders = new HttpHeaders();
  httpHeaders.set("Content-Type", "application/json");
  return this.http.post(environment.apiUrl + `getReport/transactionReport`,param, {
    responseType: "blob",
    headers: new HttpHeaders().append("Content-Type", "application/json")
  });
}

rechargeAmount(param): Observable<any> {
  const httpHeaders = new HttpHeaders();
  httpHeaders.set("Content-Type", "application/json");
  return this.http.post(environment.url + `recharge.php`,param, {
    reportProgress: true,
    responseType: "json",
  });
}

walletAmount(): Observable<any> {
  const httpHeaders = new HttpHeaders();
  httpHeaders.set("Content-Type", "application/json");
  return this.http.get(environment.apiUrl + `walletAmount`, {
    reportProgress: true,
    responseType: "json",
  });
}

searchEmployee(id): Observable<any>{
  const httpHeaders = new HttpHeaders();
  httpHeaders.set("Content-Type", "application/json");
  return this.http.get(environment.apiUrl + `employeeById/${id}`, {
    reportProgress: true,
    responseType: "json",
  });
}
}
