<div
    class="layout-wrapper"
>

    <div class="user-content" *ngIf="isAuth">
        <app-topbar (menuButtonClick)="onMenuButtonClick()"></app-topbar>
        <app-menu [active]="menuActive"></app-menu>
        <app-config></app-config>
        <div
            class="layout-mask"
            [ngClass]="{ 'layout-mask-active': menuActive }"
            (click)="onMaskClick()"
        ></div>
        <loader></loader>
        <div class="layout-content">
            <router-outlet></router-outlet>
            <app-footer></app-footer>
        </div>
    </div>

    <app-login *ngIf="!isAuth"></app-login>
</div>
<p-toast></p-toast>