import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private http: HttpClient) { }

  getAllProjects(param):Observable<any>{
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.get(
        environment.apiUrl + `project/all?page=${param.page}&limit=${param.limit}`
    )
  }

  getProjectImages(param):Observable<any>{
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.get(
        environment.apiUrl + `project/fetchProjectImages/${param}`
    )
  }

  saveProject(param):Observable<any>{
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post(
        environment.apiUrl + `project/new`,param
    )
  }

  saveProjectImages(param):Observable<any>{
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post(
        environment.apiUrl + `project/saveProjectImages`,param,{
            reportProgress: true,
            responseType: 'json'
          }
    )
  }


  uploadFiles(param):Observable<any>{
      return this.http.post(environment.apiUrl + `upload`, param)
  }
  updateProject(param,projectId):Observable<any>{
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.put(
        environment.apiUrl + `project/${projectId}`,param,{
            reportProgress: true,
            responseType: 'json'
          }
    )
  }
  deleteProject(param):Observable<any>{
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.delete(
        environment.apiUrl + `project/${param}`
    )
  }

  deleteProjectImage(param):Observable<any>{
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.delete(
        environment.apiUrl + `projectImages/${param}`
    )
  }

  deleteTag(param):Observable<any>{
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.delete(
        environment.apiUrl + `tag/${param}`
    )
  }

}
