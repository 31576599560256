import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) { }

  loadTransactions(param): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return this.http.get(
        environment.apiUrl +
            `order/all?page=${param.page}&limit=${param.limit}&startDate=${param.startDate}&endDate=${param.endDate}`
    );
}

searchTransactions(param): Observable<any> {
  const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return this.http.post(
        environment.apiUrl +
            `ordersById`,param
    );
}


getReport(param): Observable<any> {
  const httpHeaders = new HttpHeaders();
  httpHeaders.set("Content-Type", "application/json");
  return this.http.get(
      environment.apiUrl +
          `order/transactionReport?page=${param.page}&limit=${param.limit}&startDate=${param.startDate}&endDate=${param.endDate}`
  );
}

getDasboardCount(param): Observable<any> {
  const httpHeaders = new HttpHeaders();
  httpHeaders.set("Content-Type", "application/json");
  return this.http.post(
      environment.apiUrl +
          `dashboard/count`,param
  );
}
}
