import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

declare let gtag: Function;

@Component({
    selector: 'app-menu',
    template: `
        <div class="layout-sidebar" [ngClass]="{'active': active}">
            <div class="layout-menu">
                <!-- <div class="menu-category">General</div> -->
                <div class="menu-items">
                    <!-- <a [routerLink]=" ['/']" routerLinkActive="router-link-exact-active">Dashboard</a> -->
                    <a [routerLink]=" ['/dashboard']" routerLinkActive="router-link-exact-active">Dashboard</a>
                    <a [routerLink]=" ['/employees']" routerLinkActive="router-link-exact-active">Employees</a>
                    <a [routerLink]=" ['/reports']" routerLinkActive="router-link-exact-active">Transactions</a>

                </div>




            </div>
        </div>
    `,
    animations: [
        trigger('submenu', [
            state('hidden', style({
                height: '0',
                overflow: 'hidden',
                opacity: 0,
            })),
            state('visible', style({
                height: '*',
                opacity: 1
            })),
            transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
        ])
    ]
})
export class AppMenuComponent {

    @Input() active: boolean;

    activeSubmenus: {[key: string]: boolean} = {};

    constructor(private router: Router) {}
    userType:any;
    selectedSchool;

    ngOnInit(): void {
this.userType = sessionStorage.getItem('usertype');
if(this.userType == 'school'){
    this.selectedSchool = sessionStorage.getItem('schoolselected');
}

    }

    toggleSubmenu(event: Event, name: string) {
        this.activeSubmenus[name] = this.activeSubmenus[name] ? false: true;
        event.preventDefault();
    }

    isSubmenuActive(name: string) {
        if (this.activeSubmenus.hasOwnProperty(name)) {
            return this.activeSubmenus[name];
        }
        else if (this.router.isActive(name, false)) {
            this.activeSubmenus[name] = true;
            return true;
        }

        return false;
    }
}
