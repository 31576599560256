
<div>
    <ul *ngIf="projectDetails?.project_images.length">
        <li *ngFor="let item of projectDetails?.project_images">
            <div class="gallery-section">
                <img [src]="getURL(item.filePath)" alt="" width="60px">


               <div>
                <button pButton type="button" label="Delete" (click)="deleteImage(item)" class="p-button-danger"></button>
               </div>
            </div>


        </li>
    </ul>
</div>

