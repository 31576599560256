import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject,Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class InitService {
    isLoader = new BehaviorSubject(false);

    constructor(private http: HttpClient) {}

    loaderShow() {
        this.isLoader.next(true);
    }

    loaderHide() {
        this.isLoader.next(false);
    }

    getLoader() {
        return this.isLoader;
    }
    userLogin(param):Observable<any>{
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post(
            environment.apiUrl + `auth/signin`,param,{
                reportProgress: true,
                responseType: 'json'
              }
        )
      }
}
