import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class PfService {

    _loadSchoolPF = gql`
    query {
        allSchoolPfView{
          school{
            id
            schoolName
          }
          id
          fyYr
          openingBalanceLastyear
          interestReceivedLastyear
          suspanceBalanceLastyear
          totalDepositLastyear
          totalWithdrawlLastyear
          individualInterestLastyear
          unsetteledBalance
          totalDepositCurrentyear
          totalWithdrawlCurrentyear
          folioNumber
          schoolpftrnasanctionSet{
            id
            month
            openingBalance
            depositBeforeDue
            depositAfterDue
            withdrawal
            lowestBalance
            InterestAmount
            closingBalance
            interestRate

          }
        }
}`;

    _saveSchoolPF = gql`
    mutation schoolPF($school: ID, $folioNumber: String, $fy_yr: String, $individualInterestLastyear: String, $interestReceivedLastyear: String, $openingBalanceLastyear: String, $suspanceBalanceLastyear: String, $totalDepositCurrentyear: String, $totalDepositLastyear: String, $totalWithdrawlCurrentyear: String, $totalWithdrawlLastyear: String, $unsetteledBalance: String) {
        schoolPF(fyYr: $fy_yr, school: $school, folioNumber: $folioNumber, individualInterestLastyear: $individualInterestLastyear, interestReceivedLastyear: $interestReceivedLastyear, openingBalanceLastyear: $openingBalanceLastyear, suspanceBalanceLastyear: $suspanceBalanceLastyear, totalDepositCurrentyear: $totalDepositCurrentyear, totalDepositLastyear: $totalDepositLastyear, totalWithdrawlCurrentyear: $totalWithdrawlCurrentyear, totalWithdrawlLastyear: $totalWithdrawlLastyear, unsetteledBalance: $unsetteledBalance) {
          message
        }
      }

    `

     ;

    _updateSchoolPF =gql`
    mutation updateSchoolPF($schoolPfId:ID,$school: ID, $folioNumber: String, $fy_yr: String, $individualInterestLastyear: String, $interestReceivedLastyear: String, $openingBalanceLastyear: String, $suspanceBalanceLastyear: String, $totalDepositCurrentyear: String, $totalDepositLastyear: String, $totalWithdrawlCurrentyear: String, $totalWithdrawlLastyear: String, $unsetteledBalance: String){

        updateschoolpf(fyYr: $fy_yr, school: $school, folioNumber: $folioNumber, individualInterestLastyear: $individualInterestLastyear, interestReceivedLastyear: $interestReceivedLastyear, openingBalanceLastyear: $openingBalanceLastyear, suspanceBalanceLastyear: $suspanceBalanceLastyear, totalDepositCurrentyear: $totalDepositCurrentyear, totalDepositLastyear: $totalDepositLastyear, totalWithdrawlCurrentyear: $totalWithdrawlCurrentyear, totalWithdrawlLastyear: $totalWithdrawlLastyear, unsetteledBalance: $unsetteledBalance, schoolPfId:$schoolPfId){
          ok
        }
      }

    `

     ;

    _saveEmployeePF = gql`
    mutation employeePF(
        $interest:String,
        $cb_withInterest:String,
        $cb_withoutInterest:String,
        $deposit:String,
        $fyYr:String,
        $openingBalance:String,
        $retirementDate:String,
        $teachers:ID,
        $withdrawal:String,
      ){

              employeePF(
                Interest:$interest,
                balanceI:$cb_withInterest,
                balanceWi:$cb_withoutInterest,
                deposit:$deposit,
                fyYr:$fyYr,
                openingBalance:$openingBalance,
                retirementDate:$retirementDate,
                 teachers:$teachers,
                withdrawal:$withdrawal,

              ){
                message
              }
            }
    `

     ;

    _updateEmployeePF = gql`
    mutation updateEmployeePF(
        $interest:String,
        $cb_withInterest:String,
        $cb_withoutInterest:String,
        $deposit:String,
        $fyYr:String,
        $openingBalance:String,
        $retirementDate:String,
        $teachers:ID,
        $withdrawal:String,
        $pfId:ID
      ){

              editemployeePF(
                Interest:$interest,
                balanceI:$cb_withInterest,
                balanceWi:$cb_withoutInterest,
                deposit:$deposit,
                fyYr:$fyYr,
                openingBalance:$openingBalance,
                retirementDate:$retirementDate,
                 teachers:$teachers,
                withdrawal:$withdrawal,
                empPfId:$pfId

              ){
               ok
              }
            }
    `

     ;

    _removeEmployeePF = gql`
    mutation remove_schoolpf_transaction($pfId:ID){
        deleteemppfidwise(empPfId:$pfId){
          ok
        }
      }

    `;

    _saveSchoolPFTransaction = gql`
    mutation schoolPFTransaction($interestAmount: String, $closingBalance: String, $depositAfterDue:ID,
        $depositBeforeDue : String, $interestRate:String, $lowestBalance:String, $month:String, $openingBalance:String,
        $schoolPf: ID, $withdrawal: String) {
        Schoolpftrnasanction(InterestAmount:$interestAmount, closingBalance:$closingBalance, depositAfterDue:$depositAfterDue,depositBeforeDue:$depositBeforeDue,
          interestRate:$interestRate,lowestBalance:$lowestBalance,month:$month, openingBalance:$openingBalance, schoolPf:$schoolPf, withdrawal:$withdrawal
       ) {
          message
        }
      }

    `

     ;

_updateSchoolPFTransaction = gql`
mutation updateSchoolPFTransaction($interestAmount: String, $closingBalance: String, $depositAfterDue:ID,
    $depositBeforeDue : String, $interestRate:String, $lowestBalance:String, $month:String, $openingBalance:String,
    $schoolPf: ID, $withdrawal: String,$schoolPFTransactionId:ID) {
    Updateschoolpftrnasanction(InterestAmount:$interestAmount, closingBalance:$closingBalance, depositAfterDue:$depositAfterDue,depositBeforeDue:$depositBeforeDue,
      interestRate:$interestRate,lowestBalance:$lowestBalance,month:$month, openingBalance:$openingBalance, schoolPf:$schoolPf, withdrawal:$withdrawal, schoolPfTransictionId : $schoolPFTransactionId
   ) {
      ok
    }
  }`;



    _removeSchoolPF = gql`
    mutation deleteschoolpfidwise($schoolPfId:ID){

        deleteschoolpfidwise( schoolPfId:$schoolPfId){
          ok

        }
      }
    `

     ;

    _removeSchoolPFTransaction = gql`
    mutation remove_schoolpf_transaction($schoolPfTransId:ID){
        deleteschoolpftransactionidwise(schoolPfTransId:$schoolPfTransId){
          ok
        }
      }
      `
      ;

    _getSchoolPF_byID = gql`
    query($schooldPFID:ID) {
        schoolPfIdWiseView(schoolPfId:$schooldPFID){
          id
          school{
            schoolName
            id

          }
          fyYr
          openingBalanceLastyear
          interestReceivedLastyear
          suspanceBalanceLastyear
          totalDepositLastyear
          totalWithdrawlLastyear
          individualInterestLastyear
          unsetteledBalance
          totalDepositCurrentyear
          totalWithdrawlCurrentyear
          folioNumber
          schoolpftrnasanctionSet{
            id
           month
            openingBalance
            depositBeforeDue
            depositAfterDue
            withdrawal
            lowestBalance
            InterestAmount
            closingBalance
            interestRate

          }
        }
      }
    `

_getEmpPf = gql`
query{
    allEmployeePfView{
      id
      retirementDate
      openingBalance
      deposit
      withdrawal
      Interest
      balanceWi
      balanceI
      fyYr
      teachers{
        id
        name
        school{
          id
          schoolName
          iosmsId

        }
      }
    }
  }
`


  constructor(private apollo:Apollo) { }

  saveSchoolPF(data:any){
    return this.apollo.mutate({
        mutation: this._saveSchoolPF,
        variables: data,
        errorPolicy: 'all'

      })
  }

  updateSchoolPF(data:any){
    return this.apollo.mutate({
        mutation: this._updateSchoolPF,
        variables: data,
        errorPolicy: 'all'

      })
  }
  removeSchoolPF(data:any){
    data = {
        "schoolPfId":data
      }
    return this.apollo.mutate({
        mutation: this._removeSchoolPF,
        variables: data,
        errorPolicy: 'all'

      })
  }

  removeSchoolPFTransaction(data:any){
    data = {
        "schoolPfTransId":data
      }
    return this.apollo.mutate({
        mutation: this._removeSchoolPFTransaction,
        variables: data,
        errorPolicy: 'all'

      })
  }

  loadSchoolPF(){
    return this.apollo.mutate({
        mutation: this._loadSchoolPF,

        errorPolicy: 'all'

      })
  }

  loadSchoolPFbyID(data:any){
    data = {
        "schooldPFID":data
      }
    return this.apollo.mutate({
        mutation: this._getSchoolPF_byID,
        variables: data,
        errorPolicy: 'all'

      })
  }

  saveSchoolPFTransaction(data:any){
    return this.apollo.mutate({
        mutation: this._saveSchoolPFTransaction,
        variables: data,
        errorPolicy: 'all'

      })
  }
  updateSchoolPFTransaction(data:any){
    return this.apollo.mutate({
        mutation: this._updateSchoolPFTransaction,
        variables: data,
        errorPolicy: 'all'

      })
  }

  getEmpPFTransaction(){
    return this.apollo.mutate({
        mutation: this._getEmpPf,

        errorPolicy: 'all'

      })
  }

  saveEmployeePf(data:any){
      return this.apollo.mutate({
          mutation:this._saveEmployeePF,
          variables: data,
          errorPolicy:'all'
      })
  }
  updateEmployeePf(data:any){
    return this.apollo.mutate({
        mutation:this._updateEmployeePF,
        variables: data,
        errorPolicy:'all'
    })
}
deleteEmployeePf(id:any){
   let data = {
        "pfId":id
      }
    return this.apollo.mutate({
        mutation:this._removeEmployeePF,
        variables: data,
        errorPolicy:'all'
    })
}

}
