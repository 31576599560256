<loader></loader>
<div class="card">
    <h5>Blog List</h5>
    <p-toolbar styleClass="p-mb-4">
        <ng-template pTemplate="left">
            <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success p-mr-2" (click)="isDisplay = true"></button>

        </ng-template>

    </p-toolbar>

    <p-table #dt1 [value]="tipsList"  [showCurrentPageReport]="true"
         [globalFilterFields]="['name,tags,']"
        >

        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="name" style="width: 20rem">Blog Name
                    <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                </th>

                <th>tags
                    <p-columnFilter type="text" field="tags" display="menu"></p-columnFilter>
                </th>

                <th>Banner</th>
                <th>Description</th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <td class="format-text"> {{item.name}}</td>
                <td>
                    <span class="chips" *ngFor="let tag of item?.tags">
                        {{tag?.name}}
                    </span>
                </td>

                <td><img [src]="getURL(item.banner)" [alt]="item.title" width="100" class="shadow-4" /></td>
                <th class="format-description"><span >{{item.description1+item.description2}}</span></th>
                <td>
                    <p-splitButton label="Actions"   [model]="
                    action(item)
                    "></p-splitButton>
                    <!-- <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editProduct(item)"></button>
                    <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteProduct(item)"></button> -->
                </td>
            </tr>
        </ng-template>


    </p-table>

    <p-paginator (onPageChange)="onPageChange($event)" [first]="1" [rows]="10" [totalRecords]="pagination.total" [rowsPerPageOptions]="[10, 20, 30]"></p-paginator>
    <p-dialog  [(visible)]="isDisplay" [style]="{width: '80vw'}"
    [header]="isUpdate == true ? 'Update Blog' : 'Create Blog'"
      styleClass="p-fluid">
       <form [formGroup]="blogForm" (submit)="saveBlog()" >
        <div class="p-grid">
            <div  class="p-field p-md-12 p-sm-12">
                <label for="name">Blog Name</label>
                <input type="text" pInputText id="name" formControlName="name" required autofocus />
                <!-- <small class="p-invalid" *ngIf="submitted && !product.name">Name is required.</small> -->
            </div>

            <div class="p-field p-md-12 p-sm-12">
                <label for="name">Tags</label>
                <p-chips [(ngModel)]="tags" [ngModelOptions]="{standalone: true}" (onRemove)="removeTag($event.value)"></p-chips>
                <!-- <small class="p-invalid" *ngIf="submitted && !product.name">Name is required.</small> -->
            </div>
            <div class="p-field p-md-12 p-sm-12">
                <label for="name">banner</label>
                <!-- <input type="text" pInputText id="name" formControlName="banner" required autofocus /> -->
               <div>
                <input
                type="file"
                name="myfile"
                (change)="uploadBanner($event)"
                class="inputfile"
            />
               </div>
            <!-- <small class="p-invalid" *ngIf="submitted && !product.name">Name is required.</small> -->
            <div  *ngIf="blogForm.get('banner')?.value">
                <img

                    [src]="getURL(blogForm.get('banner').value)"
                    width="40px"
                />
            </div>


                  <!-- <small class="p-invalid" *ngIf="submitted && !product.name">Name is required.</small> -->
            </div>

            <div class="p-field p-md-12 p-sm-12">
                <label for="name">Description 1</label>
                <p-editor  [(ngModel)]="description1" [ngModelOptions]="{standalone: true}" [style]="{'height':'200px'}"></p-editor>
                <!-- <input type="text" pInputText id="name" formControlName="description1" required autofocus /> -->
                <!-- <small class="p-invalid" *ngIf="submitted && !product.name">Name is required.</small> -->
            </div>

            <div class="p-field p-md-12 p-sm-12">
                <label for="name">Description 2</label>
                <p-editor [(ngModel)]="description2" [ngModelOptions]="{standalone: true}" [style]="{'height':'200px'}"></p-editor>
                <!-- <small class="p-invalid" *ngIf="submitted && !product.name">Name is required.</small> -->
            </div>

            <div class="p-field p-md-4 p-sm-12 p-md-offset-4">
                <button pButton pRipple label="Save" [disabled]="blogForm.invalid && tags?.length == 0"  type="submit"  class="p-button-primary" ></button>
            </div>
</div>

       </form>

    </p-dialog>
</div>
<p-toast position="top-right"></p-toast>
