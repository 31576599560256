import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppConfigService } from '../../service/appconfigservice';
import { AppConfig } from '../../domain/appconfig';
import { Subscription } from 'rxjs';
import { InitService } from '../../service/init.service';
import { SchoolService } from '../../service/school.service';
import { TeacherService } from '../../service/teacher.service';
import { DivisionService } from '../../service/division.service';

@Component({
    selector: 'app-dashboard',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {

    config: AppConfig;
    schools:any[];
    subscription: Subscription;
    teacherList:any[];
    divisionList:any[];
    userType;
    noticeList:any[];
    currentFYear:any;

    constructor(private configService: AppConfigService,private initService:InitService,
        private schoolService:SchoolService, private teacherService:TeacherService, private divisionService:DivisionService) {}

    ngOnInit() {
this.userType = sessionStorage.getItem('usertype');
this.currentFYear = Number(sessionStorage.getItem('currentFYear'));
        // this.loadSchool();
        // this.loadDivision();
        // this.loadTeachers();
        // this.config = this.configService.config;
        // this.subscription = this.configService.configUpdate$.subscribe(config => this.config = config);
    }

    loadDivision() {
        this.initService.loaderShow();
        // this.divisionService.loadDivisions().subscribe(({ data }: any) => {
        //     this.initService.loaderHide();
        //     this.divisionList = data.divisionallview;
        // }, (error) => {
        //     // console.log('there was an error sending the query', error);
        // });
    }

    loadTeachers() {
        this.initService.loaderShow();
        this.teacherService.loadTeachers().subscribe(({ data }: any) => {
            this.initService.loaderHide();


                this.teacherList = data.allTeacherView;
                if(this.userType == 'school'){
                    this.teacherList = this.teacherList.filter(item=>item.school.id == sessionStorage.getItem('schoolselected'));
                }

            // this.teacherForm.reset();

        }, (error) => {
            console.log('there was an error sending the query', error);
        });
    }


    loadSchool() {
        this.initService.loaderShow();
        this.schoolService.loadSchools().subscribe(({ data }: any) => {
            this.initService.loaderHide();

            this.schools = data.schoolAllView;
        }, (error) => {
            this.initService.loaderHide();
            console.log('there was an error sending the query', error);
        });
    }


    ngOnDestroy() {
        if (this.subscription) {
            //this.subscription.unsubscribe();
        }
    }



}
