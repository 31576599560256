import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class SchoolService {
    createSchoolQuery = gql`
    mutation school(
        $BOUNDARYWall:String,
        $DRINKINGWater:String,
        $Designation:String,
        $ELECTRICConnection:String,
        $SCHOOLStatus:String,
        $SCHOOLType:String,
        $diseCode:String,
        $hoiAddress:String,
        $hoiContact:String,
        $hoiName:String,
        $hoiWhatsaap:String,
        $iosmsId:String,
        $schoolCategory:String,
        $schoolContact:String,
        $schoolEmail:String,
        $schoolName:String,
        $toilets:String,
        $totalBuildings:String,
        $totalClassRoom:String,
        $totalGrc:String,
        $totalGrd:String,
        $totalSanctionPost:String,
      ){
        scho0l(

          BOUNDARYWall:$BOUNDARYWall,
          DRINKINGWater:$DRINKINGWater,
          Designation:$Designation,
          ELECTRICConnection:$ELECTRICConnection,
          SCHOOLStatus:$SCHOOLStatus,
          SCHOOLType:$SCHOOLType,
          diseCode:$diseCode,
          hoiAddress:$hoiAddress,
          hoiContact:$hoiContact,
          hoiName:$hoiName,
          hoiWhatsaap:$hoiWhatsaap,
          iosmsId:$iosmsId,
          schoolCategory:$schoolCategory,
          schoolContact:$schoolContact,
          schoolEmail:$schoolEmail,
          schoolName:$schoolName,
          toilets:$toilets,
          totalBuildings:$totalBuildings,
          totalClassRoom:$totalClassRoom,
          totalGrc:$totalGrc,
          totalGrd:$totalGrd,
          totalSanctionPost:$totalSanctionPost,
        ){

          mesg
        }


      }`;

      _updateSchool = gql`
      mutation updateSchool(
        $BOUNDARYWall:String,
        $DRINKINGWater:String,
        $Designation:String,
        $ELECTRICConnection:String,
        $SCHOOLStatus:String,
        $SCHOOLType:String,
        $diseCode:Int,
        $hoiAddress:String,
        $hoiContact:String,
        $hoiName:String,
        $hoiWhatsaap:String,
        $iosmsId:String,
        $schoolCategory:String,
        $schoolContact:String,
        $schoolEmail:String,
        $schoolName:String,
        $toilets:String,
        $totalBuildings:String,
        $totalClassRoom:String,
        $totalGrc:String,
        $totalGrd:String,
        $totalSanctionPost:String,
  	$schoolId:Int
      ){
        editSchool(

          BOUNDARYWall:$BOUNDARYWall,
          DRINKINGWater:$DRINKINGWater,
          Designation:$Designation,
          ELECTRICConnection:$ELECTRICConnection,
          SCHOOLStatus:$SCHOOLStatus,
          SCHOOLType:$SCHOOLType,
          diseCode:$diseCode,
          hoiAddress:$hoiAddress,
          hoiContact:$hoiContact,
          hoiName:$hoiName,
          hoiWhatsaap:$hoiWhatsaap,
          iosmsId:$iosmsId,
          schoolCategory:$schoolCategory,
          schoolContact:$schoolContact,
          schoolEmail:$schoolEmail,
          schoolName:$schoolName,
          toilets:$toilets,
          totalBuildings:$totalBuildings,
          totalClassRoom:$totalClassRoom,
          totalGrc:$totalGrc,
          totalGrd:$totalGrd,
          totalSanctionPost:$totalSanctionPost,
          schoolid:$schoolId
        ){

          mesgsuccess
        }


      }
      `

   getSchool = gql`
   query{
    schoolAllView{
     id
      schoolName
      iosmsId
      schoolContact
      schoolCategory
      SCHOOLStatus
      diseCode{
        newUdiseCode
        blockName
        circleName
      }
    }
    }
   `;


   getSchoolById = gql`
   query($schoolId:ID){
    schoolIdWiseView(schoolid:$schoolId){
      id
      schoolName
      iosmsId
      hoiName
      Designation
      hoiAddress
      hoiContact
      hoiWhatsaap
      schoolContact
      schoolEmail
      schoolCategory
      totalClassRoom
      totalBuildings
      totalSanctionPost
      totalGrd
      totalGrc
      toilets
      BOUNDARYWall
      ELECTRICConnection
      DRINKINGWater
      SCHOOLType
      SCHOOLStatus
      diseCode {
        id
        blockName
        circleName
        oldUdiseCode
        newUdiseCode
      }

      studentSet{
        id
        male
        Total
        female
        TotalAadhar
        result
        TotalPfNo
        stdName
        school{
            id
            schoolName
          }

      }
      teachersSet{

        id
         name
         designation
         gender
         subject
         caste
         phone
         whatsapp
         emailId
         address
         school{
           id
           schoolName
         }


       }
    }
    }
`
   ;


  constructor(private apollo:Apollo) { }


  loadSchools(){
    return this.apollo.mutate({
        mutation: this.getSchool,


      })
}

loadSchoolById(schoolId){
    let param = {
        "schoolId":  schoolId
        }

    return this.apollo.mutate({
        mutation: this.getSchoolById,
        variables: param,


      })
}

updateSchool(SchoolInfo:any){
return this.apollo.mutate({
    mutation:this._updateSchool,
    variables:SchoolInfo,
})
}

createSchool(data:any){
    return this.apollo.mutate({
        mutation: this.createSchoolQuery,
        variables: data,

      })
}
}
