<div class="card">
    <h5>All Transaction Details</h5>
    <p-toolbar styleClass="p-mb-4">
        <input type="text" pInputText  [(ngModel)]="searchText" (input)="search()" placeholder="Search Employee Id">
    
        <ng-template pTemplate="left">
            <!-- <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success p-mr-2" (click)="openNew()"></button> -->
            <p-calendar placeholder="Date Range" [(ngModel)]="selectedDate" selectionMode="range"  [readonlyInput]="true"></p-calendar>
            <p-button icon="pi pi-search" (click)="loadTransactions()" class="p-col-2"></p-button>
           
            <button type="button" label="Download Report" pButton pRipple icon="pi pi-file-excel" (click)="downloadReport()" class="p-button-success mr-2" pTooltip="Download Report" tooltipPosition="bottom"></button>
           
        </ng-template>


        

<!-- 
        <ng-template pTemplate="right">

            <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button>
        </ng-template> -->
    </p-toolbar>
    <p-tag class="p-mb-4" [rounded]="true">
        <div class="p-2">Report Generated From <strong>{{selectedDate[0] | date }} - {{selectedDate[1]|date}}</strong></div>
    </p-tag>
 

    <p-table #dt1 [value]="transactionList"  [showCurrentPageReport]="true"
    [globalFilterFields]="['name']" (sortFunction)="customSort($event)" [resizableColumns]="true"    [customSort]="true" 
    
   >

   <ng-template pTemplate="header">
       <tr>
           <th pSortableColumn="id" >Transaction Id
            <p-sortIcon field="id"></p-sortIcon>
           </th>
           <th pSortableColumn="empId">Employee Id
            <p-sortIcon field="empId"></p-sortIcon>
        </th>

           <th pSortableColumn="amount">Amount
            <p-sortIcon field="amount"></p-sortIcon>
           </th>

           <th pSortableColumn="type">Transaction Type <p-sortIcon field="type"></p-sortIcon></th>
           <th pSortableColumn="trnxDate">Transaction Date <p-sortIcon field="trnxDate"></p-sortIcon></th>
           <th pSortableColumn="createdBy">Created By <p-sortIcon field="createdBy"></p-sortIcon></th>
           
           <!-- <th></th> -->
       </tr>
   </ng-template>
   <ng-template pTemplate="body" let-item>
       <tr>
           <td class="format-text"> {{item.id}}</td>
           <td class="format-text"> {{item.employeId}}</td>
           
           <td class="format-text"> {{item.amount |  currency:"INR"}}</td>
           <td class="format-text" [ngClass]="item.status == 'credit'?'green':'red'" > {{(item.status == 'debit'?'Payment':'Recharge') | titlecase}}</td>

           <td class="format-text"> {{item.createdAt | date: 'dd/MM/yyyy' }}</td>
           <td class="format-text"> {{item.created_by | titlecase }}</td>
           

           
           <!-- <td>
               <p-splitButton label="Actions"   [model]="
               action(item)
               "></p-splitButton>
              
           </td> -->
       </tr>
   </ng-template>


</p-table>


<p-paginator (onPageChange)="onPageChange($event)" [first]="1" [rows]="10" [totalRecords]="pagination.total" [rowsPerPageOptions]="[10, 50, 100]"></p-paginator>
 
<label>Total {{pagination.total > 0 ?pagination.total : 0}} records. </label>

</div>
