<loader></loader>
<div class="container" [hidden]="!isMessage">
  <p class="message" [(ngClass)]="msgs.type">{{msgs?.message}}</p>
</div>
<div class="login-panel">
    <!-- <div class="language-menu">
      <select (change)="onLanguageChange($event)">
        <option value="en" [selected]="locale == 'en'">English</option>
        <option value="hi" [selected]="locale == 'hi'">Hindi</option>
      </select>
    </div> -->
    <div class="p-grid p-nogutter p-justify-center p-align-center" style="height:100%">
      <div class="p-xl-3 p-lg-6 p-md-8 p-sm-10">
        <p-panel >
          <div class="p-col-12">
            <p-messages [(value)]="msgs">Welcome</p-messages>
          </div>
          <form [formGroup]="loginForm" (ngSubmit)="onLogin()">
          <div class="p-grid p-justify-center p-align-center">
            <img alt="logo" style="width: 70px !important;" [src]="'../../assets/img/brand.png'" />
            <div class="p-sm-12 p-text-center">
              <h4>Welcome to RSP Canteen Department  </h4>
            </div>

            <div class="p-col-12">
              <div class="ui-inputgroup">
                <span class="ui-inputgroup-addon"><i class="fa fa-user"></i></span>
                <input  id="input-username" formControlName="username" type="text" style="width: 100%;" pInputText
                  placeholder="Enter User name">
                  <!-- <div *ngIf="isSubmitted && fcontrol?.username?.errors" class="invalid-feedback">

                      <small  *ngIf="fcontrol?.username?.errors?.required" class="text-red" id="username-help" >Username is required.</small>

                </div> -->

              </div>
            </div>
            <div class="p-col-12">
              <div class="ui-inputgroup">
                <span class="ui-inputgroup-addon"><i class="fa fa-key"></i></span>
                <input id="input-password" formControlName="password" type="password" style="width: 100%;" pInputText
                  placeholder="Enter Password"  >
                  <!-- <div *ngIf="isSubmitted && fcontrol?.password?.errors" class="invalid-feedback">

                    <small  *ngIf="fcontrol?.password?.errors?.required" class="text-red" id="username-help" >Password is required.</small>

              </div> -->
              </div>
            </div>
            
            <div class="p-col-12 text-center">
              <a>Forgot Password?</a>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-offset-3 p-col-6">
              <button pButton type="submit" label="Login" class="p-col-12 ui-button-raised"
                ></button>
            </div>

          </div>
        </form>
        </p-panel>

      </div>
    </div>
  </div>
