<div class="card">
    <h5>All Employee Details</h5>
    <p-toolbar styleClass="p-mb-4">
        <ng-template pTemplate="left">
            <input type="text" pInputText  [(ngModel)]="searchText" (input)="search()" placeholder="Search Employee Id">
            
        </ng-template>

        <ng-template pTemplate="right">
            <button
            *ngIf="userRole =='account'"
                pButton
                pRipple
                label="Add New"
                icon="pi pi-plus"
                class="p-button-success p-mr-2"
                (click)="openNew()"
            ></button>
            <button
            *ngIf="userRole =='account'"
                pButton
                pRipple
                label="Recharge"
                (click)="rechargeModel = true"
                icon="pi pi-upload"
                class="p-button-primary p-mr-2"
            ></button>
            <button
                type="button"
                pButton
                pRipple
                label="Download"
                icon="pi pi-file-excel"
                (click)="downloadReport()"
                class="p-button-success p-mr-2"
                pTooltip="Download Report"
                tooltipPosition="bottom"
            ></button>
       
        </ng-template>
    </p-toolbar>

    <div>
       
    </div>
   

    <p-table
        #dt1
        [value]="employeesList"
        [showCurrentPageReport]="true"
        [globalFilterFields]="['name']"
    >
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="name" style="width: 20rem">
                    RSP Personal Number
                    <p-columnFilter
                        type="text"
                        field="name"
                        display="menu"
                    ></p-columnFilter>
                </th>

                <th>
                    Name
                    <p-columnFilter
                        type="text"
                        field="tags"
                        display="menu"
                    ></p-columnFilter>
                </th>

                <th>Phone</th>
                <th>Wallet Balance</th>
                <th  *ngIf="userRole =='account'"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <td class="format-text">{{ item.employeId }}</td>
                <td class="format-text">{{ item.name }}</td>
                <td class="format-text">{{ item.phone }}</td>

                <td class="format-text">{{ item.wallet }}</td>

                <td  *ngIf="userRole =='account'">
                    <p-splitButton
                        label="Actions"
                        [model]="action(item)"
                    ></p-splitButton>
                    <!-- <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editProduct(item)"></button>
               <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteProduct(item)"></button> -->
                </td>
            </tr>
        </ng-template>
    </p-table>

    <p-paginator
        (onPageChange)="onPageChange($event)"
        [first]="1"
        [rows]="10"
        [totalRecords]="pagination.total"
        [rowsPerPageOptions]="[10, 50, 100]"
    ></p-paginator>
    <p-dialog
        [(visible)]="isDisplay"
        [style]="{ width: '80vw' }"
        [header]="
            isUpdate == true ? 'Update Employee' : 'Register New Employee'
        "
        styleClass="p-fluid"
    >
        <form [formGroup]="employeeForm" (submit)="onSave()">
            <div class="p-grid">
                <div class="p-field p-md-6 p-sm-12">
                    <label for="name">RSP Personal Number</label>
                    <input
                        type="text"
                        pInputText
                        id="name"
                        formControlName="employeId"
                        required
                        autofocus
                    />
                    <!-- <small class="p-invalid" *ngIf="submitted && !product.name">Name is required.</small> -->
                </div>
                <div class="p-field p-md-6 p-sm-12">
                    <label for="name">Employee Name</label>
                    <input
                        type="text"
                        pInputText
                        id="name"
                        formControlName="name"
                        required
                        autofocus
                    />
                    <!-- <small class="p-invalid" *ngIf="submitted && !product.name">Name is required.</small> -->
                </div>
                <div class="p-field p-md-12 p-sm-12">
                    <label for="name">Contact Number</label>
                    <input
                        type="text"
                        pInputText
                        id="name"
                        formControlName="phone"
                        required
                        autofocus
                    />
                    <!-- <small class="p-invalid" *ngIf="submitted && !product.name">Name is required.</small> -->
                </div>

                <div class="p-field p-md-4 p-sm-12 p-md-offset-4">
                    <button
                        pButton
                        pRipple
                        label="Save"
                        [disabled]="employeeForm.invalid"
                        type="submit"
                        class="p-button-primary"
                    ></button>
                </div>
            </div>
        </form>
    </p-dialog>

    <p-dialog
        [(visible)]="rechargeModel"
        [style]="{ width: '80vw' }"
        header="Recharge Empoyee Balance"
        styleClass="p-fluid"
    >
        <div class="p-grid">
            <div class="p-field p-md-6 p-sm-12">
                <input type="file" name="" [(ngModel)]="rechargeFile" (change)="onUpload($event)" id="" />
                <!-- <small class="p-invalid" *ngIf="submitted && !product.name">Name is required.</small> -->
            </div>
        </div>

       
            
          
        <div style="height: 300px;
        overflow: scroll">
            <p *ngIf="records.length > 0">
                {{ records.length }} Records uploaded.
            </p>
            <table
                class="table"
                *ngIf="records.length > 0"
                style="width: 100%; text-align-last: center; padding: 0 20px"
            >
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Employee Id</th>
                        <th scope="col">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let record of records; let i = index">
                        <th scope="row">{{ record.id }}</th>
                        <td>{{ record.amount }}</td>
                    </tr>
                </tbody>
            </table>

            <div *ngIf="response.data">
             
                <p-tag icon="pi pi-check" severity="success" value="Success - {{response.success}}"></p-tag>
            <p-tag icon="pi pi-times" severity="danger" value="Fail -  {{response.fail}}"></p-tag>
                
                <table
                class="table"
                *ngIf="response.data"
                style="width: 100%; text-align-last: center;"
            >
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Employee Id</th>
                        <th scope="col">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let record of response.data; let i = index">
                        <th scope="row">{{ record.id }}</th>
                        <td>{{ record.status }}</td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>


      

        <div
            class="justify-content-center"
            style="display: flex; justify-content: space-evenly"
        >
            <button
                pButton
                pRipple
                label="Save"
                [disabled]="records.length <= 0"
                (click)="rechargeDataRecords()"
                class="btn p-button-primary"
            ></button>
            <button
                pButton
                pRipple
                label="Cancel"
                (click)="closeRecharge()"
                class="btn p-button-primary"
            ></button>
        </div>
    </p-dialog>


    <p-dialog
    [(visible)]="isPasswordReset"
    [style]="{ width: '80vw' }"
    [header]="'Password Reset'"
    styleClass="p-fluid"
>
   
        <div class="p-grid">
            <div class="p-field p-md-6 p-sm-12">
                <label for="name">Enter New Password</label>
                <input
                    type="password"
                    pInputText
                   [(ngModel)]="resetPassword"
                    autofocus
                />
                <!-- <small class="p-invalid" *ngIf="submitted && !product.name">Name is required.</small> -->
            </div>
         

            <div class="p-field p-md-4 p-sm-12 p-md-offset-4">
                <button
                    pButton
                    pRipple
                    label="Reset"
                    [disabled]="resetPassword==''"
                    (click)="updateEmpPassword()"
                    class="p-button-primary"
                ></button>
            </div>
        </div>
 
</p-dialog>
</div>
