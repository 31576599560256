import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Toaster } from "ngx-toast-notifications";

import { InitService } from "src/app/showcase/service/init.service";
import { PfService } from "src/app/showcase/service/pf.service";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
    public loginForm: FormGroup;
    msgs = {
        message: "",
        type: "",
    };

    schoolDetails;
    isMessage = false;
    isSubmitted = false;
    constructor(
        private formBuilder: FormBuilder,
        private pfService: PfService,
        private router: Router,
        private initService: InitService,
        private toast: Toaster,
       
    ) {}
    currentYear: any = new Date().getFullYear();
    ngOnInit(): void {
        //location.reload();
        // this.router.navigateByUrl('/dashboard');
        if(sessionStorage.getItem("authKey")){
            this.router.navigateByUrl('/');
        }
        this.loginForm = this.formBuilder.group({
            username: ["", Validators.required],
            password: ["", Validators.required],
        });
    }

    fcontrol() {
        return this.loginForm.controls;
    }

    onLogin() {
        this.initService.userLogin(this.loginForm.value).subscribe((res) => {
            if (res?.accessToken  && res?.accessToken != null && (res.role == "admin" || res.role == 'account')) {
                sessionStorage.setItem("isAuth", "true");
                sessionStorage.setItem("authKey", res?.accessToken);
                sessionStorage.setItem("userID",res.username)
                sessionStorage.setItem("userRole",res.role)
                // this.router.navigateByUrl('');
                location.reload();
            }
            else{
                this.toast.open({text:"Incorrent Username or Password. Please try again.",position:"top-right"});
            }
           
        },
        (err)=>{
            this.toast.open({text:"Incorrent Username or Password. Please try again.",position:"top-right"});
        });
    }
}
