import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InitService } from 'src/app/showcase/service/init.service';
import { ReportService } from 'src/app/showcase/service/report.service';
import * as FileSaver from 'file-saver';
import { DivisionService } from 'src/app/showcase/service/division.service';
import { saveAs } from "file-saver";
import * as moment from 'moment';
interface Column {
  field: string;
  header: string;
  customExportHeader?: string;
}
interface ExportColumn {
  title: string;
  dataKey: string;
}
@Component({
  selector: 'report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  isDisplay = false;
  isUpdate = false;
  transactionList:any=[];
  selectedDate:any;
  pagination = {
    page: 1,
    limit: 10,
    total:0
};

// maxDate = moment().utc().toDate();
// minDate =moment().subtract(6, 'months').utc().toDate();
searchText:string = "";
  public transactionForm: FormGroup;
    division: any[];

    productDialog = false;
    first = 0;

    rows = 10;
    exportColumns!: ExportColumn[];
    cols!: Column[];
  userRole: string;
    //selectedProducts;

 // selectedEmployee: any;
    constructor(
      private reportService: ReportService,
      private employeeService: DivisionService,
       private fb: FormBuilder,
       private initService:InitService,
       //private messageService: MessageService
       ) { 
        if(sessionStorage.getItem('userRole')){
          this.userRole = sessionStorage.getItem('userRole');
        }
       }

    ngOnInit(): void {
      this.selectedDate = [moment().startOf("day").utc().toDate(),moment().startOf("day").utc().toDate()];
      this.transactionForm  = this.fb.group({
        employeId:['',Validators.required],
        transactionType:['',Validators.required],
        amount:['',Validators.required],
        created_by:['',Validators.required]
        
      })
        this.loadTransactions();
        this.cols = [
          { field: 'id', header: 'Transaction Id' },
          { field: 'employeId', header: 'Employee Id' },
          { field: 'amount', header: 'Amount' },
          { field: 'type', header: 'Transaction Type' },
          { field: 'createdAt', header: 'Transaction Date' }
      ];
       
        this.exportColumns = this.cols.map((col) => ({ title: col.header, dataKey: col.field }));
    }
    customSort(e){

    }

    search(){
      this.searchText = this.searchText.trim();
      if(this.searchText != "" && this.searchText.length > 2){

        this.initService.loaderShow();
        let startDate =  moment().startOf('month');
        let endDate =  moment().endOf('month');
        if(this.selectedDate != undefined && this.selectedDate.length == 2){
           startDate = moment(this.selectedDate).startOf('month');
           endDate =  moment(this.selectedDate).endOf('month');
        }
        
        let param = {
          page: this.pagination.page,
          limit: this.pagination.limit,
          startDate: startDate,
          endDate: endDate,
          employeId:this.searchText
      };
        this.reportService.searchTransactions(param).subscribe(res => {
            this.initService.loaderHide();
            this.transactionList = res.data;
            this.pagination.total = res.total;
        }, (error) => {
            // console.log('there was an error sending the query', error);
        });
      }
    }
    exportPdf() {
      import('jspdf').then((jsPDF) => {
          import('jspdf-autotable').then((x) => {
              const doc = new jsPDF.default('p', 'px', 'a4');
              (doc as any).autoTable(this.exportColumns, this.transactionList);
              doc.save('products.pdf');
          });
      });
  }

  exportExcel() {
      import('xlsx').then((xlsx) => {
          const worksheet = xlsx.utils.json_to_sheet(this.transactionList);
          const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          this.saveAsExcelFile(excelBuffer, 'products');
      });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
}


dailyTransaction(){
 
    this.selectedDate[0] = moment().startOf("day");
    this.selectedDate[1] =  moment().endOf('day');
    this.loadTransactions()
  }

  monthlyTransaction(){
    this.selectedDate[0] = moment().startOf("month");
    this.selectedDate[1] =  moment().endOf('month');
    this.loadTransactions()
  }

 


    loadTransactions() {
      //  this.initService.loaderShow();
        var date = new Date();
        console.log(this.selectedDate);
        
        let startDate =  moment().startOf('month');
        let endDate =  moment().endOf('month');
        if(this.selectedDate != undefined && this.selectedDate.length == 2){
          startDate = moment(this.selectedDate[0]).startOf("day");
          endDate =  moment(this.selectedDate[1]).endOf('day');
        }
        
        let param = {
          page: this.pagination.page,
          limit: this.pagination.limit,
          startDate: startDate,
          endDate: endDate
      };
        this.reportService.loadTransactions(param).subscribe(res => {
            this.initService.loaderHide();
            this.transactionList = res.data;
            this.pagination.total = res.total;
        }, (error) => {
            // console.log('there was an error sending the query', error);
        });
    }

    downloadReport(){
      this.initService.loaderShow();
      var date = new Date();
      let startDate =  moment().startOf('month');
      let endDate =  moment().endOf('month');
      if(this.selectedDate != undefined && this.selectedDate.length == 2){
        startDate = moment(this.selectedDate[0]).startOf("day");;
        endDate =  moment(this.selectedDate[1]).endOf('day');
      }
      
      let param = {
        // page: this.pagination.page,
        // limit: this.pagination.limit,
        startDate: startDate,
        endDate: endDate
    };
    if(this.searchText != ""){param['empId'] = this.searchText}
      this.employeeService.orderReport(param).subscribe(res => {
          this.initService.loaderHide();
          saveAs(res, "employee.xlsx");
          // this.transactionList = res.data;
          // this.pagination.total = res.total;
      }, (error) => {
          // console.log('there was an error sending the query', error);
      });
    }

    onSave(){
      // if(this.transactionForm.valid){
      //   if(this.isUpdate){
      //     this.employeeService.updateEmployee(this.transactionForm.value,this.selectedEmployee).subscribe(res=>{
  
      //     if(res.status === 'success'){
      //       this.isUpdate = false;
      //       this.isDisplay = false;
      //       this.transactionForm.reset();
      //     //   this.messageService.add({
      //     //     severity: "success",
      //     //     summary: "Record Update Successfully",
      //     //     detail: "Message Content",
      //     // });
      //     this.loadTransactions();
      //     }
      //     else{
      //     //   this.messageService.add({
      //     //     severity: "error",
      //     //     summary: "Record not been updated Successfully",
      //     //     detail: "Message Content",
      //     // });
      //     }
          
  
      //     })
  
      //   }
      //   else{
      //     let param = {
      //       name:this.transactionForm.value.name,
      //       status:1,
      //       password:"123",
      //       phone:this.transactionForm.value.phone,
      //       wallet:0,
      //       employeId:this.transactionForm.value.employeId
      //     }
      //     this.employeeService.saveEmployee(param).subscribe(res=>{
      //       if(res.status === 'success'){
      //         this.isDisplay = false;
      //         this.transactionForm.reset();
      //       //   this.messageService.add({
      //       //     severity: "success",
      //       //     summary: "Record Update Successfully",
      //       //     detail: "Message Content",
      //       // });
      //       this.loadTransactions();
      //       }
      //       else{
      //       //   this.messageService.add({
      //       //     severity: "error",
      //       //     summary: "Record not been updated Successfully",
      //       //     detail: "Message Content",
      //       // });
      //       }
      //     })
      //   }

      // }
    
    }

    action(details) {
      return [
  
          {
              label: "Update",
              icon: "pi pi-pencil",
              command: () => {
                  this.updateEmployee(details);
                   this.isDisplay = true;
              },
          },
          {
              label: "Delete",
              icon: "pi pi-times",
              command: () => {
                 this.deleteEmployee(details.id);
              },
          },
      ];
  }

  deleteEmployee(id){
// if(id != ""){
//   this.employeeService.deleteEmployee(id).subscribe(res=>{
//     if(res.status === 'success'){
//     //   this.messageService.add({
//     //     severity: "success",
//     //     summary: "Record Update Successfully",
//     //     detail: "Message Content",
//     // });
//     this.loadTransactions();
//     }
//     else{
//     //   this.messageService.add({
//     //     severity: "error",
//     //     summary: "Record not been updated Successfully",
//     //     detail: "Message Content",
//     // });
//     }


// })
//   }
}

  onPageChange(e){
    console.log(e);
    this.pagination.page = e.page+1;
    this.pagination.limit = e.rows;
    this.loadTransactions();

}

openNew(){
  this.isUpdate = false;
  this.isDisplay = true;
}
updateEmployee(empDetails){
  this.isUpdate = true;
 // this.selectedEmployee = empDetails.id;
  // this.transactionForm.get('name').setValue(empDetails.name);
  // this.transactionForm.get('phone').setValue(empDetails.phone);
  // this.transactionForm.get('employeId').setValue(empDetails.employeId);
}


}
