import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { InitService } from "src/app/showcase/service/init.service";
import { ProjectService } from "src/app/showcase/service/project.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "project-gallery",
    templateUrl: "./project-gallery.component.html",
    styleUrls: ["./project-gallery.component.css"],
    providers: [MessageService],
})
export class ProjectGalleryComponent implements OnInit {
    constructor(
        private projectService: ProjectService,
        private route: ActivatedRoute,
        private initService:InitService,
        private messageService:MessageService
    ) {
        this.projectId = this.route.snapshot.paramMap.get("id");
    }
    projectDetails: any;
    projectId: any;

    ngOnInit(): void {
        this.fetchImages();

    }
    fetchImages() {
        this.projectService
            .getProjectImages(this.projectId)
            .subscribe((res) => {
                this.projectDetails = res;
            });
    }
    getURL(url){
        return environment.url+url;
    }
    deleteImage(data){
        this.initService.loaderShow();
        this.projectService.deleteProjectImage(data?.id).subscribe((res) => {
             this.messageService.add({
            severity: "danger",
            summary: "Message",
            detail: "Image Deleted Successfully",
        });
            this.initService.loaderHide();
            this.fetchImages();
        });
    }
}
