import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AppConfigService } from './service/appconfigservice';
import { AppConfig } from './domain/appconfig';
import { Subscription } from 'rxjs';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { InitService } from './service/init.service';
import { PfService } from './service/pf.service';

declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    providers: [MessageService]
})
export class AppComponent implements OnInit, OnDestroy {

    menuActive: boolean;

    newsActive: boolean = true;

    config: AppConfig;

    news_key = 'primenews';
    isAuth:any = false;
    public subscription: Subscription;
    currentYear:any = new Date().getFullYear();
    schoolDetails:any;


    constructor(private router: Router,
        private configService: AppConfigService,
        private primengConfig: PrimeNGConfig,
        private initService:InitService,
        private pfService:PfService,
        private route:ActivatedRoute) {



    }

    ngOnInit() {

        this.isAuth = sessionStorage.getItem('isAuth');
        // this.primengConfig.ripple = true;
        // this.config = this.configService.config;
        // this.subscription = this.configService.configUpdate$.subscribe(config => this.config = config);

    }





    removeClass(element: any, className: string) {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }



    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
