import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";


@Injectable({
    providedIn: "root",
})
export class BlogService {
    constructor(private http: HttpClient) {}

    getAllBlogs(param): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set("Content-Type", "application/json");
        return this.http.get(
            environment.apiUrl +
                `blog/all?page=${param.page}&limit=${param.limit}`
        );
    }

    saveBlog(param): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set("Content-Type", "application/json");
        return this.http.post(environment.apiUrl + `blog/new`, param);
    }

    uploadFiles(param):Observable<any>{
        return this.http.post(environment.apiUrl + `upload`, param)
    }
    updateBlog(param, blogId): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set("Content-Type", "application/json");
        return this.http.put(environment.apiUrl + `blog/${blogId}`, param, {
            reportProgress: true,
            responseType: "json",
        });
    }
    createTags(param): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set("Content-Type", "application/json");
        return this.http.post(environment.apiUrl + `tags/new`, param, {
            reportProgress: true,
            responseType: "json",
        });
    }
    deleteBlog(param): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set("Content-Type", "application/json");
        return this.http.delete(environment.apiUrl + `blog/${param}`);
    }
}
